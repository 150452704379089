import { mapActions, mapState } from 'vuex'
import { myMixin } from '../../../mixins'
import store from '../../../store'

export default{
  mixins: [myMixin],
  data: () => ({
    teste: false,
    renderComponent: true,
    renderComponent2: true,
    eventSelectedOpen: {},
    titleDate: '',
    progress: false,
    events: [],
    showActions: false,
    selectedOpenNew: false,
    showDelete: false,
    loadingDelete: false,
    items: [],
    focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        '4day': '4 Dias',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      
  }),
  watch:{
      $route (to, from){
        this.checkPermission();  
      }
  },
    mounted(){
        this.$refs.calendar.checkChange();
        this.checkPermission();
      },
    
    created(){
      
      this.getData();
    },
    
    computed: {
      ...mapState('auth', ['menu']),
    },
    
    methods: {
      ...mapActions('calendar', ['ActionSearchCalendars', 'ActionDeleteCalendar']),

      checkPermission(){
        this.showActions = this.$route.name == 'calendar-register';
      },
      
      getData(){
      
        this.progress = true;
        this.selectedOpenNew = false;
        this.items = [];

        var payload = {
          with: 'states,eventScope,eventType,eventItem,categories,publics,cities,campuses,brands,areas',
          per_page:   10000,
        };

        this.ActionSearchCalendars(payload)
            .then((res) => {
                this.items = res.data;
                this.setEvents();
            })
            .finally(() => {
                this.progress = false;
            });
      },

      deleteItem()
        {
            
            this.loadingDelete = true;

            this.ActionDeleteCalendar({id: this.selectedEvent.id})
                .then((res) => {
                    this.message = res.message;
                    this.getData();
                })
                .catch((error) =>{
                    this.error      = true;
                    this.errors     = error.errors;
                    this.message    = error.message;
                })
                .finally(() => {
                    this.loadingDelete = false;
                    this.showDelete = false;
                    
                });
        
        },

      setEvents(){

        this.events = [];

        this.items.forEach(event => {
          
          var color;

          if(event.color && event.color != ''){
            color = event.color;
          }else if(event.event_item.color && event.event_item.color != ''){
            color = event.event_item.color;
          }else if(event.event_type.color && event.event_type.color != ''){
            color = event.event_type.color;
          }else if(event.color && event.color != ''){
            color = event.color;
          }else{
            color = 'cyan';
          }
          
          event.name = event.event_item.name;
          event.start = event.date_start;
          event.end = event.date_end;
          event.color = color
          
          this.events.push(event);

        });

        this.forceRerender();
        this.updateTitle();

      },

      forceRerender () {
        
        this.renderComponent = false;

        this.$nextTick(() => {
          this.renderComponent = true;
        });

      },

      updateTitle(){
        this.titleDate = this.$refs.calendar.title;
      },

      openNew(){

        Object.assign(this.eventSelectedOpen, this.selectedEvent);

        this.selectedOpenNew = true;

      },

      viewDay ({ date }) {
        this.focus = date;
        this.type = 'day';
      },

      addEvent(){
        this.eventSelectedOpen = {};
        this.eventSelectedOpen.date_start = this.focus;
        this.eventSelectedOpen.date_end = this.focus;
        this.selectedOpenNew = true;
      },

      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = '';
      },
      prev () {
        this.$refs.calendar.prev();
      },
      next () {
        this.$refs.calendar.next();
      },
      
      showEvent ({ nativeEvent, event }) {
        
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation();

      },

      updateRange ({ start, end }) {
        
        // const events = [];

        // events.push({
        //     name: 'Recesso do Professor',
        //     start: '2021-03-03',
        //     end: '2021-03-09',
        //     color: 'cyan',
        //     timed: false,
        //   });

        // this.events = events;

        // const min = new Date(`${start.date}T00:00:00`);
        // const max = new Date(`${end.date}T23:59:59`)
        // const days = (max.getTime() - min.getTime()) / 86400000
        // const eventCount = this.rnd(days, days + 20)

        // for (let i = 0; i < eventCount; i++) {
          
        //   const allDay = this.rnd(0, 3) === 0
        //   const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        //   const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        //   const second = new Date(first.getTime() + secondTimestamp)
        
        //   events.push({
        //     name: this.names[this.rnd(0, this.names.length - 1)],
        //     start: first,
        //     end: second,
        //     color: this.colors[this.rnd(0, this.colors.length - 1)],
        //     timed: !allDay,
        //   })
        // }

        // this.events = events
      },

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      getRelations(items, key = 'name'){
        
        if(items && items.length > 0){
          return items.map(o => o[key]).join(' | ');
        }else{
          return "";
        }
      }

    },
}
